
import { defineComponent } from "vue";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import { mapState } from "vuex";
import store from "@/store";
import InputText from "primevue/inputtext";
import PartsService from "@/services/PartsService";
import Dialog from "primevue/dialog";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import Utils from "@/utility/utils";

const partService = new PartsService();

export default defineComponent({
  name: "Details",
  mounted() {
    (this.$refs.part as any).$el.focus();
    (this.$refs.part as any).$el.select();
  },
  computed: {
    ...mapState(["inventory"]),
    inventoryItems(): any {
      const items: any = [];
      this.inventory.invloc_items.forEach((item: any) => {
        if (item.bin_items) {
          item.bin_items.map((bin: any) => {
            const quantity = parseFloat(bin.bin_qty) ?? 0;
            if (quantity != 0) {
              items.push({
                inv_loc: item.invloc,
                bin: bin.bin,
                qty: bin.bin_qty,
              });
            }
          });
        } else {
          const quantity = parseFloat(item.on_hand) ?? 0;
          if (quantity != 0) {
            items.push({ inv_loc: item.invloc, bin: "", qty: item.on_hand });
          }
        }
      });
      return items;
    },
  },
  components: {
    DataTable,
    Column,
    Dialog,
    InputText,
    LoadingSpinner,
  },
  data() {
    return {
      expandedRows: [],
      options: [],
      part: "",
      part_desc: "",
      loading: false,
      showOptions: false,
      validData: false,
      rowClass: "p-field p-col col-12 m-0 p-0 py-1 flex justify-content-center",
      rowClassText: "p-field p-col col-12 m-0 p-0 flex justify-content-left",
    };
  },
  methods: {
    reset() {
      return this.$router.push("/scan/inventory");
    },
    getInventory(part_no: string) {
      store
        .dispatch("inventory/fetchInventory", {
          client: store.getters["session/getClient"],
          id: part_no,
          correls: "um",
        })
        .then(() => {
          this.validData = true;
        })
        .finally(() => {
          (this.$refs.part as any).$el.select();
          this.loading = false;
        });
    },
    pickPart(part: any) {
      this.showOptions = false;
      this.part = part.data.part_no;
      this.part_desc = this.formatToLineBreak(part.data.wrap_desc);
      this.getInventory(this.part);
    },
    searchPart() {
      this.part = Utils.validateScanInput(this.part, true);

      this.validData = false;
      if (!this.part) {
        const notification = {
          type: "error",
          message: "Enter part number.",
        };
        store.dispatch("notification/add", notification, { root: true });
        return;
      } else {
        this.loading = true;
        partService
          .getPartsInventory(
            store.getters["session/getClient"],
            this.part,
            "part_no wrap_desc status"
          )
          .then((resp: any) => {
            if (resp.parts_items.length === 1) {
              this.part = resp.parts_items[0].part_no;
              this.part_desc = this.formatToLineBreak(
                resp.parts_items[0].wrap_desc
              );
              this.getInventory(this.part);
            } else if (resp.parts_items.length > 1) {
              this.showOptions = true;
              this.options = resp.parts_items;
            } else {
              const notification = {
                type: "error",
                message: "No part found.",
              };
              store.dispatch("notification/add", notification, { root: true });
            }
            this.loading = false;
          });
      }
    },
    formatToLineBreak(text: string) {
      return Utils.formatCharToLineBreak(text);
    },
  },
});
